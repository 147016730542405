@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

















































































.participation-summary {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  .v-btn__content {
    letter-spacing: 0;
    font-size: size(17);
  }
}
